const actions = {
  SET_DEPOSIT: 'SET_DEPOSIT',
  SET_WITHDRAW: 'SET_WITHDRAW',
  SET_TRANSFER: 'SET_TRANSFER',
  SET_VERIFICATION: 'SET_VERIFICATION',
  SET_NOTIFICATION: 'SET_NOTIFICATION',
  RESET_NOTIFICATION: 'RESET_NOTIFICATION',
  setDeposit: (deposit) => {
    return {
      type: actions.SET_DEPOSIT,
      deposit: deposit
    }
  },
  setWithdraw: (withdraw) => {
    return {
      type: actions.SET_WITHDRAW,
      withdraw: withdraw
    }
  },
  setTransfer: (transfer) => {
    return {
      type: actions.SET_TRANSFER,
      transfer: transfer
    }
  },
  setVerification: (verification) => {
    return {
      type: actions.SET_VERIFICATION,
      verification: verification
    }
  },
  setNotification: (notification) => {
    return {
      type: actions.SET_NOTIFICATION,
      notification: notification
    }
  },
  resetNotification: () => {
    return {
      type: actions.RESET_NOTIFICATION
    }
  }
}
export default actions
