import actions from './actions'

const initState = {
  deposit: 0,
  withdraw: 0,
  transfer: 0,
  verification: 0
}

export default function (state = initState, action) {
  switch (action.type) {
    case actions.SET_DEPOSIT:
      return {
        ...state,
        deposit: action.deposit
      }
    case actions.SET_WITHDRAW:
      return {
        ...state,
        withdraw: action.withdraw
      }
    case actions.SET_TRANSFER:
      return {
        ...state,
        transfer: action.transfer
      }
    case actions.SET_VERIFICATION:
      return {
        ...state,
        verification: action.verification
      }
    case actions.SET_NOTIFICATION:
      console.log(action.notification)

      return {
        ...state,
        ...action.notification
      }
    case actions.RESET_NOTIFICATION:
      console.log(action.notification)

      return {
        ...initState
      }
    default:
      return state
  }
}
