import antdSA from "antd/lib/locale-provider/fr_FR"
import saMessages from "../locales/fr_FR.json"

const saLang = {
  messages: {
    ...saMessages,
  },
  antd: antdSA,
  locale: "fr-FR",
}
export default saLang
