import React from 'react'
import {
  SidebarEmailIcon,
  SidebarProfileIcon,
  SidebarBlankIcon,
  SidebarEcommerceIcon,
  SidebarChatIcon,
  SidebarTablesIcon,
  SidebarInvoiceIcon,
  SidebarContactsIcon,
  SidebarFormsIcon,
  SidebarScrumBoardIcon,
  SidebarChartsIcon,
  SidebarShuffleIcon,
  SidebarMenuLevelIcon,
  SidebarHomeIcon
} from '@iso/config/icon.config'

import { FaDonate } from 'react-icons/fa'
import { GiStack } from 'react-icons/gi'
const options = [
  {
    key: '/dashboard/investors',
    label: 'home',
    leftIcon: <SidebarHomeIcon size={19} />,
    access: [2]
  },
  {
    key: '/dashboard/investors/users',
    label: 'investor',
    leftIcon: <SidebarProfileIcon size={19} />,
    access: [2]
  },
  {
    key: '/dashboard/investors/transactions',
    label: 'private_portfolio',
    leftIcon: <SidebarInvoiceIcon size={19} />,
    access: [2]
  },
  {
    key: '/dashboard',
    label: 'home',
    leftIcon: <SidebarHomeIcon size={19} />,
    access: [0, 1, 9]
  },
  {
    key: '/dashboard/users',
    label: 'users',
    leftIcon: <SidebarProfileIcon size={19} />,
    access: [0, 1]
  },
  {
    key: '/dashboard/audits',
    label: 'audits',
    leftIcon: <SidebarInvoiceIcon size={19} />,
    children: [
      {
        key: '/dashboard/audits/deposit',
        label: 'deposit'
      },
      {
        key: '/dashboard/audits/withdraw',
        label: 'withdraw'
      },
      {
        key: '/dashboard/audits/transfer',
        label: 'transfer'
      },
      {
        key: '/dashboard/audits/wallet',
        label: 'wallet.transaction'
      }
    ],
    access: [0, 1]
  },
  {
    key: '/dashboard/statistics',
    label: 'statistics',
    leftIcon: <SidebarShuffleIcon size={19} />,
    access: [0, 1, 9]
  },
  {
    key: '/dashboard/reports',
    label: 'reports',
    leftIcon: <SidebarFormsIcon size={19} />,
    access: [0, 1, 9]
  },
  {
    key: '/dashboard/plans',
    label: 'plan',
    leftIcon: <SidebarChartsIcon size={19} />,
    access: [0, 1]
  },
  {
    key: '/dashboard/waitings',
    label: 'waiting',
    leftIcon: <GiStack size={19} />,
    access: [0, 1]
  },
  {
    key: '/dashboard/investments',
    label: 'investment',
    leftIcon: <FaDonate size={19} />,
    access: [0, 1]
  },
  {
    key: '/dashboard/profits',
    label: 'profits',
    leftIcon: <SidebarBlankIcon size={19} />,
    access: [0, 1]
  },
  {
    key: '/dashboard/currencies',
    label: 'currencies',
    leftIcon: <SidebarMenuLevelIcon size={19} />,
    access: [0, 1]
  },
  // {
  //   key: '/dashboard/investors/currencies',
  //   label: 'currencies',
  //   leftIcon: <SidebarMenuLevelIcon size={19} />,
  //   access: [2]
  // },
  {
    key: '/dashboard/versions',
    label: 'versions',
    leftIcon: <SidebarScrumBoardIcon size={19} />,
    access: [0, 1]
  },
  {
    key: '/dashboard/announcements',
    label: 'announcements',
    leftIcon: <SidebarFormsIcon size={19} />,
    access: [0, 1]
  },
  {
    key: '/dashboard/investors/announcements',
    label: 'announcements',
    leftIcon: <SidebarFormsIcon size={19} />,
    access: [2]
  },
  {
    key: '/dashboard/faqs',
    label: 'faqs',
    leftIcon: <SidebarTablesIcon size={19} />,
    access: [0, 1]
  },
  {
    key: '/dashboard/feedbacks',
    label: 'feedbacks',
    leftIcon: <SidebarChatIcon size={19} />,
    access: [0, 1]
  },
  {
    key: '/dashboard/notifications',
    label: 'notifications',
    leftIcon: <SidebarEmailIcon size={19} />,
    access: [0, 1]
  }
]

export default options
