import client from '../request'
import _ from 'lodash'

const getAllPending = () => {
  return client.get(`/notifications/pending/count`)
}

const getReport = (props) => {
  let filterString = ''
  let filters = props?.filters ?? null
  if (!_.isEmpty(filters)) {
    _.forEach(filters, function (value, key) {
      filterString += `&${key}=${value}`
    })
  }
  return client.get(`/notifications/report?${filterString}`)
}

const getReportScoped = (props) => {
  let filterString = ''
  let filters = props?.filters ?? null
  if (!_.isEmpty(filters)) {
    _.forEach(filters, function (value, key) {
      filterString += `&${key}=${value}`
    })
  }
  return client.get(`/notifications/report-scoped?${filterString}`)
}

const getInvestorReport = (props) => {
  let filterString = ''
  let filters = props?.filters ?? null
  if (!_.isEmpty(filters)) {
    _.forEach(filters, function (value, key) {
      filterString += `&${key}=${value}`
    })
  }
  return client.get(`/notifications/investor-report?${filterString}`)
}

const getStats = (props) => {
  let filterString = ''
  let filters = props?.filters ?? null
  if (!_.isEmpty(filters)) {
    _.forEach(filters, function (value, key) {
      filterString += `&${key}=${value}`
    })
  }
  return client.get(`/notifications/stats?foo=bar${filterString}`)
}

const getSubscribeStats = (props) => {
  let filterString = ''
  let filters = props?.filters ?? null
  if (!_.isEmpty(filters)) {
    _.forEach(filters, function (value, key) {
      filterString += `&${key}=${value}`
    })
  }
  return client.get(`/notifications/subscribe-stats?year=${props.year}${filterString}`)
}

const getTerminateStats = (props) => {
  let filterString = ''
  let filters = props?.filters ?? null
  if (!_.isEmpty(filters)) {
    _.forEach(filters, function (value, key) {
      filterString += `&${key}=${value}`
    })
  }
  return client.get(`/notifications/terminate-stats?year=${props.year}${filterString}`)
}

const getMonthlyReport = (props) => {
  let filterString = ''
  let filters = props?.filters ?? null
  if (!_.isEmpty(filters)) {
    _.forEach(filters, function (value, key) {
      filterString += `&${key}=${value}`
    })
  }
  return client.get(`/notifications/monthly-report?month=${props.month}${filterString}`)
}

export default {
  getAllPending,
  getReport,
  getStats,
  getSubscribeStats,
  getInvestorReport,
  getMonthlyReport,
  getTerminateStats,
  getReportScoped
}
