import { proxy } from 'valtio'
import _ from 'lodash'
import { subscribe } from 'valtio'

const initialValue = {
  selectedRole: 2,
  adminType: 9
}
const storageName = 'global_store'
let storedGlobal = { ...initialValue }
if (window.localStorage) {
  let result = JSON.parse(localStorage.getItem(storageName))
  if (!_.isEmpty(result)) {
    console.log(result)
    storedGlobal = result
  }
}

export const global = proxy({
  state: storedGlobal,
  reset() {
    global.state = { selectedRole: 2 }
  }
})

subscribe(global, () => {
  console.log('state.arr has changed to', global.state)
  localStorage.setItem(storageName, JSON.stringify(global.state))
})
