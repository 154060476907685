import React, { lazy, Suspense, useEffect } from 'react'
import {
  Route,
  Redirect,
  BrowserRouter as Router,
  Switch,
  useLocation,
  useHistory
} from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import ErrorBoundary from './ErrorBoundary'
import { PUBLIC_ROUTE } from './route.constants'
import Loader from '@iso/components/utility/loader'

import NotificationActions from '@iso/redux/notification/actions'
import NotificationService from '@iso/lib/network/services/notification'
import { global as globalStore } from '@iso/lib/store/global'
import { useProxy } from 'valtio'

const Dashboard = lazy(() => import('./containers/Dashboard/Dashboard'))
const publicRoutes = [
  {
    path: PUBLIC_ROUTE.LANDING,
    exact: true,
    component: lazy(() => import('@iso/containers/Pages/SignIn/SignIn'))
  },
  {
    path: PUBLIC_ROUTE.SIGN_IN,
    component: lazy(() => import('@iso/containers/Pages/SignIn/SignIn'))
  }
]

function PrivateRoute({ children, ...rest }) {
  const snapshot = useProxy(globalStore)
  const isLoggedIn = useSelector((state) => state.Auth.token)
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()

  const fetchNotifications = async () => {
    const result = await NotificationService.getAllPending()
    return { notification: result }
  }

  const refreshNotifications = async () => {
    console.log('refreshNotifications', snapshot.state.selectedRole)
    if (snapshot.state.selectedRole == 0 || snapshot.state.selectedRole == 1) {
      dispatch(NotificationActions.resetNotification())
      const result = await fetchNotifications()
      console.log(result)
      dispatch(NotificationActions.setNotification(result.notification))
    } else {
      dispatch(NotificationActions.resetNotification())
    }
  }

  useEffect(() => {
    refreshNotifications()
  }, [location])

  // useEffect(() => {
  //   if (snapshot.state.selectedRole == 0 || snapshot.state.selectedRole == 1) {
  //     history.push('/dashboard')
  //   } else {
  //     history.push('/dashboard/investors')
  //   }
  // }, [snapshot])

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isLoggedIn ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/signin',
              state: { from: location }
            }}
          />
        )
      }
    />
  )
}
export default function Routes() {
  return (
    <ErrorBoundary>
      <Suspense fallback={<Loader />}>
        <Router>
          <Switch>
            {publicRoutes.map((route, index) => (
              <Route key={index} path={route.path} exact={route.exact}>
                <route.component />
              </Route>
            ))}

            <PrivateRoute path="/dashboard">
              <Dashboard />
            </PrivateRoute>
          </Switch>
        </Router>
      </Suspense>
    </ErrorBoundary>
  )
}
